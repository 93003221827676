import React from "react";
import * as PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import indieIcon from "../visual/badge-indie-OberionIO.min.svg";
import IconSupplier from "../../element/graphical/IconSupplier";
import TourHeaderSupplier from "./TourHeaderSupplier";
import RatingTooltip from "./RatingTooltip";
import StoreButton from "./StoreButton";

const useStyles = makeStyles(theme => ({
  card: {
    display: "flex",
    flexFlow: "row wrap",
    backgroundColor: "rgba(0,0,44,0.6)",
  },
  contentNoBottomMargin: {
    margin: "0.5rem 0.5rem 0 0.5rem",
    padding: "0.5rem 0.5rem 0 0.5rem !important",
  },
  button: {
    border: "solid 2px !important",
    padding: "0.3rem 0.5rem",
    textTransform: "none",
    margin: "0 auto",
  },
  rightIcon: {
    height: "1.5rem",
    marginLeft: theme.spacing(1),
  },
  chip: {
    margin: "0.2rem 0.5rem 0.1rem 0",
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

const GamePreviewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${props => props.alignment};
  align-items: center;
  margin: 1.5rem 0;
`;
const RowDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
`;

const text = [
  "Why you might like this",
  "Available on",
];


const GamePreview = ({ alignment, game }) => {
  const classes = useStyles();
  // console.log(game);
  // console.log(game.gameHeader);

  return (
    <GamePreviewContainer alignment={alignment}>
      <Card className={classes.card}>

        <RowDiv>
          <TourHeaderSupplier gameHeader={game.gameHeader} />

          <CardContent className={classes.contentNoBottomMargin}>
            <Typography variant="h6" gutterBottom>{game.gameName}</Typography>
            <Typography variant="caption" paragraph>{`by ${game.developer}`}</Typography>
            <RatingTooltip userRating={(game.score / 20)} score={game.score} ratings={game.ratings} />
          </CardContent>
        </RowDiv>
        <RowDiv>
          <CardContent className={classes.content}>
            {/*<Divider component="hr" light />*/}
            <Typography variant="caption">{game.shortDescription}</Typography>
            <Divider className={classes.divider} component="hr" light />

            <Typography variant="h6" gutterBottom>{text[0]}</Typography>
            {game.gameChip.map((element) => {
              return (
                <Chip label={element}
                      className={classes.chip}
                      color="secondary"
                      size="small"
                      icon={<IconSupplier caption="Indie" icon={indieIcon} />}
                      component="div" key={element.slice(0, 10)} />
              );
            })}
            {game.reasonsToPlay.map((element, index) => {
              return (
                <Chip label={element}
                      className={classes.chip}
                      variant="outlined"
                      icon={<DoneIcon />}
                      component="div"
                      key={`${element.slice(0, 3)}-${index}`} />
              );
            })}
            <Divider className={classes.divider} component="hr" light />

            <RowDiv>
              <Typography variant="caption" component="span">{text[1]}</Typography>
              <div>
              {Object.keys(game.storeLinks).map(store => (
                <StoreButton store={store} storeHref={game.storeLinks[store]} key={store} />
              ))}
              </div>
            </RowDiv>
          </CardContent>
        </RowDiv>
      </Card>
    </GamePreviewContainer>
  );
};
GamePreview.defaultProps = {
  alignment: "flex-start",
};
GamePreview.propTypes = {
  alignment: PropTypes.string,
  game: PropTypes.shape({
    gameId: PropTypes.number.isRequired,
    gameHeader: PropTypes.string.isRequired,
    gameName: PropTypes.string.isRequired,
    developer: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    ratings: PropTypes.number.isRequired,
    shortDescription: PropTypes.string.isRequired,
    gameChip: PropTypes.arrayOf(PropTypes.string),
    reasonsToPlay: PropTypes.arrayOf(PropTypes.string),
    storeLinks: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
};

export default GamePreview;