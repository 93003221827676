import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import Layout from "../element/layout/Layout";
import { InnerColumnContainer } from "../element/layout/LayoutSetBackground";
import { ooContentWidth } from "../element/theme";
import { lessOrEqualThan, greaterOrEqualThan } from "../util/mediaQuery";
import LayoutColumn from "../element/layout/LayoutColumn";
import OoSpacing from "../element/page-element/OoSpacing";
import SEO from "../element/SEO";
import TourUserAvatar from "./TourUserAvatar";
import TouredGames from "./TouredGames";
import Stats from "./Stats";
import starsRight
  from "./visual/background-discovery-tour-stars-right-OberionIO.min.svg";
import starsLeft
  from "./visual/background-discovery-tour-stars-left-OberionIO.min.svg";
import waveBottom
  from "./visual/background-discovery-tour-wave-bottom-OberionIO.min.svg";
import waveMiddle
  from "./visual/background-discovery-tour-wave-middle-va1-OberionIO.min.svg";
import waveTop from "./visual/background-discovery-tour-wave-top-OberionIO.min.svg";
import avatar from "../library/visual/try-random-robot-OberionIO.min.svg";
import UserFavorites from "./UserFavorites";
import TourFeedback from "./TourFeedback";

const seo = {
  title: "Discovery Tour - Oberion",
  description: "Discovery Tour",
};
const componentSpacing = 6;
const heading = [
  "Your Discovery Tour",
  "Seven handpicked recommendations for you",
];

const BackgroundDiv = styled(InnerColumnContainer)`
  background-color: #3c00be;
  background-image: url(${starsRight}), url(${starsLeft}), url(${waveTop}), url(${waveMiddle}), url(${waveBottom});
  background-size: auto, auto, 100%, 100%, 100%;
  background-position: top right, top left, top center, center center, bottom center;
  background-repeat: repeat-y, repeat-y, no-repeat, no-repeat, no-repeat;
  
  align-items: center;
  justify-content: center;
`;
const GrowDiv = styled.div` 
  ${lessOrEqualThan.md`width: 90vw`};
  width: 80vw;
  ${greaterOrEqualThan.lg`width: 58vw;`};
  flex: 1;
`;
const ColumnDiv = styled.div`   
  display: flex; 
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
`;

const Tour = ({ data }) => {
  return (
    <Layout hideNewsletter>
      <SEO {...seo} />
      <BackgroundDiv>
        <LayoutColumn alignItems="center"
                      justifyContent="center"
                      textAlign="left"
                      maxWidth={ooContentWidth}
                      paddingBottom="5rem"
                      paddingTop="5rem">

          <Typography variant="h1" align="center" gutterBottom>{heading[0]}</Typography>

          <ColumnDiv>
            <TourUserAvatar src={avatar} />
            <Typography variant="h5" gutterBottom paragraph>{data.user.username}</Typography>
            <UserFavorites data={data.user} />
          </ColumnDiv>

          <OoSpacing marginTop={componentSpacing} />
          <Typography variant="h3" align="center" paragraph>{heading[1]}</Typography>
          <GrowDiv>
            <TouredGames games={data.games} />
          </GrowDiv>

          <Stats stats={data.stats} />

          <OoSpacing marginTop={componentSpacing} />
          <TourFeedback />

        </LayoutColumn>
      </BackgroundDiv>
    </Layout>
  );
};
Tour.propTypes = {
  data: PropTypes.shape({
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
    }).isRequired,
    games: PropTypes.array.isRequired,
    stats: PropTypes.object.isRequired,
  }).isRequired,
};
export default Tour;
