import React from "react";
import { Button, Typography } from "@material-ui/core";
import styled from "styled-components";
import Input from "@material-ui/core/Input";
import { InternalRoute, ROUTES } from "../navigation/InternalRoute";
import { ooTextWidth } from "../element/theme";
import OoSpacing from "../element/page-element/OoSpacing";
import { ConciseList } from "../element/page-element/ConciseList";
import { ConciseLegalNotice } from "../navigation/footer/NewsletterLegalText";

const introText = [
  "Feedback",
  "One more minute of your feedback will help us make this site better. Thanks for checking out our project!",
  "Your feedback is anonymous.",
  "We do not store your reddit user name or any other of your personal information. Check out the ",
  "privacy policy",
  ".",
  "This page will be deleted by 15 September 2019.",
];
const feedbackText = [
  "Do the suggestions fit your preferences?",
  "Have you found new games that you haven't heard about yet through our suggestions?",
  "Have you added or do you plan to add any of the games to a wishlist of yours?",
  "Have you bought or do you plan to buy any of the suggested games?",
  "Anything else you would like to tell us?",
  "Game selection, design, favorite french fry, ...",
];

export const FeedbackContainer = styled.div`
  max-width: ${ooTextWidth}rem;
  display: flex; 
  flex-flow: column nowrap;
  align-items: flex-start;
  
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  padding: 1.5rem 1rem 1rem 1rem;
`;
const QuestionDiv = styled.div`
  margin-top: 0.5rem;
  align-items: start;
  justify-content: left;
  text-align: left;
`;
const LeftAlignedConciseLegalNotice = styled(ConciseLegalNotice)`
  margin: 1rem 0 0 0;
`;
const MarginBottomDiv = styled.div`
  margin-bottom: 1rem;
`;

const TourFeedback = () => (
  <>
    <Typography variant="h2" gutterBottom>{introText[0]}</Typography>

    <FeedbackContainer>
      <Typography variant="body1">{introText[1]}</Typography>
      <QuestionDiv>
        <form name="reddit-feedback"
              method="POST"
              // enable netlify forms
              // data-netlify="true"
              // netlify-honeypot="bot-field"
              action={ROUTES.THANKS.TO}>

          <p className="hidden" style={{ visibility: "hidden" }}>
            <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
          </p>
          <input name="form-name" value="reddit-feedback" type="hidden" />

          <Typography variant="body1">{feedbackText[0]}</Typography>
          <div>
            <input type="radio" id="fittingSuggestionsYes" name="fittingSuggestions" value="yes" />
            <label htmlFor="fittingSuggestionsYes">Yes</label>
          </div>
          <MarginBottomDiv>
            <input type="radio" id="fittingSuggestionsNo" name="fittingSuggestions" value="no" />
            <label htmlFor="fittingSuggestionsNo">No</label>
          </MarginBottomDiv>

          <Typography variant="body1">{feedbackText[1]}</Typography>
          <div>
            <input type="radio" id="newGamesYes" name="newGames" value="yes" />
            <label htmlFor="newGamesYes">Yes</label>
          </div>
          <MarginBottomDiv>
            <input type="radio" id="newGamesNo" name="newGames" value="no" />
            <label htmlFor="newGamesNo">No</label>
          </MarginBottomDiv>

          <Typography variant="body1">{feedbackText[2]}</Typography>
          <div>
            <input type="radio" id="wishlistYes" name="wishlist" value="yes" />
            <label htmlFor="wishlistYes">Yes</label>
          </div>
          <MarginBottomDiv>
            <input type="radio" id="wishlistNo" name="wishlist" value="no" />
            <label htmlFor="wishlistNo">No</label>
          </MarginBottomDiv>

          <Typography variant="body1">{feedbackText[3]}</Typography>
          <div>
            <input type="radio" id="boughtGamesYes" name="boughtGames" value="yes" />
            <label htmlFor="boughtGamesYes">Yes</label>
          </div>
          <MarginBottomDiv>
            <input type="radio" id="boughtGamesNo" name="boughtGames" value="no" />
            <label htmlFor="boughtGamesNo">No</label>
          </MarginBottomDiv>

          <MarginBottomDiv>
            <Typography variant="body1" gutterBottom>{feedbackText[6]}</Typography>
            <Input
              type="text"
              name="generalFeedback"
              placeholder={feedbackText[7]}
              multiline
              fullWidth
              inputProps={{
                maxLength: 1000,
              }}
            />
          </MarginBottomDiv>
          <OoSpacing marginTop="1rem" />
          <Button variant="contained" color="secondary" size="large"
                  type="submit" value="Submit">
            Send Feedback
          </Button>
        </form>

        <OoSpacing marginTop="1rem" />
        <LeftAlignedConciseLegalNotice>
          <ConciseList>
            <li>
              <Typography variant="caption">{introText[2]}</Typography>
            </li>
            <li>
              <Typography variant="caption">
                {introText[3]}
                <InternalRoute caption={introText[4]} to={ROUTES.PRIVACY.TO} />
                {introText[5]}
              </Typography>
            </li>
            <li>
              <Typography variant="caption" gutterBottom>{introText[6]}</Typography>
            </li>
          </ConciseList>
        </LeftAlignedConciseLegalNotice>
      </QuestionDiv>
    </FeedbackContainer>
  </>
);

export default TourFeedback;