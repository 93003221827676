import React from "react";
import * as PropTypes from "prop-types";
import GamePreview from "./game-preview/GamePreview";

const TouredGames = ({ games }) => {
  return (
    <>
      {games.map((element, index) => {
        const alignment = (index % 2) ? "flex-end" : "flex-start";
        return (
          <GamePreview key={element.gameName} alignment={alignment} game={element} />
        );
      })}
    </>
  );
};
TouredGames.propTypes = {
  games: PropTypes.array.isRequired,
};
export default TouredGames;
