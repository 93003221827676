export const data = {
  user: {
    userId: 6,
    username: "HackNSlash_915",
    avatar: "",
    tourNumber: 12,
    top3AllTimeGames: ["The Legend Of Zelda: Breath of the Wild", "Dishonored", "Darksiders 2"],
    top3IndieGames: ["Hollow Knight", "Dead Cells", "Sanctuary"],
    favoriteGenres: ["Hack N' Slash", "Shooter", "Open World"],
    favoriteSettings: ["Souls-like", "Fantasy", "Adventure", "3D Castlevania style", "Similar to Bayonetta", "Tomb Raider-like", "Shooter + Hack N' Slash Combo"],
  },
  games: [
    {
      gameId: 1,
      gameName: "Prey (2017)",
      score: 87,
      ratings: 13847,
      gameHeader: "prey.jpg",
      developer: "Arcane Studios",
      shortDescription: "In Prey, you awaken aboard Talos I, a space station orbiting the moon in the year 2032. You are the key subject of an experiment meant to alter humanity forever – but things have gone terribly wrong. The space station has been overrun by hostile aliens and you are now being hunted.",
      gameChip: ["From Dishonored Developer"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/480490/Prey/",
      },
      reasonsToPlay: ["Shooter", "First Person", "Similar to Dishonored & Bayonetta"],
      awards: [""],
    },
    {
      gameId: 2,
      gameName: "METAL GEAR RISING: REVENGEANCE",
      score: 92,
      ratings: 13003,
      gameHeader: "metal-gear-rising-revengeance.jpg",
      developer: "Platinum Games",
      shortDescription: "Developed by Kojima Productions and PlatinumGames, METAL GEAR RISING: REVENGEANCE takes the renowned METAL GEAR franchise into exciting new territory with an all-new action experience. The game seamlessly melds pure action and epic story-telling that surrounds Raiden – a child soldier transformed into a half-human, half-cyborg ninja who...",
      gameChip: ["From Bayonetta Developer"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/235460/METAL_GEAR_RISING_REVENGEANCE/",
      },
      reasonsToPlay: ["Hack N' Slash", "Third Person", "Similar to Dishonored & Bayonetta"],
      awards: [""],
    },
    {
      gameId: 3,
      gameName: "Celeste",
      score: 97,
      ratings: 9306,
      gameHeader: "celeste.jpg",
      developer: "Matt Making Games",
      shortDescription: "Help Madeline survive her inner demons on her journey to the top of Celeste Mountain, in this super-tight platformer from the creators of TowerFall. Brave hundreds of hand-crafted challenges, uncover devious secrets, and piece together the mystery of the mountain. ",
      gameChip: ["Indie Gem"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/504230/Celeste/",
      },
      reasonsToPlay: ["Platformer", "Similar to Hollow Knight"],
      awards: [""],
    },
    {
      gameId: 4,
      gameName: "ICEY",
      score: 91,
      ratings: 17431,
      gameHeader: "icey.jpg",
      developer: "FantaBlade Network",
      shortDescription: "ICEY is a 2D side-scrolling action game and a META game in disguise. As you follow the narrator's omnipresent voice, you will see through ICEY's eyes and learn the truth about her world. The narrator will constantly urge you in one direction, but you must ask, \"Why? Why am I following his directions?“",
      gameChip: ["Indie Gem"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/553640/ICEY/",
      },
      reasonsToPlay: ["Castlevania", "Hack N' Slash", "Similar to Dead Cells"],
      awards: [],
    },
    {
      gameId: 5,
      gameName: "Monster Hunter: World",
      score: 73,
      ratings: 74562,
      gameHeader: "monster-hunter-world.jpg",
      developer: "Capcom",
      shortDescription: "Welcome to a new world! In Monster Hunter: World, the latest installment in the series, you can enjoy the ultimate hunting experience, using everything at your disposal to hunt monsters in a new world teeming with surprises and excitement. ",
      gameChip: ["Hunting Monsters"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/582010/MONSTER_HUNTER_WORLD/",
      },
      reasonsToPlay: ["Loot", "Shooter", "Hack N' Slash"],
      awards: [""],
    },
    {
      gameId: 6,
      gameName: "The Hunter: Call Of The Wild",
      score: 79,
      ratings: 20060,
      gameHeader: "the-hunter-call-of-the-wild.jpg",
      developer: "Expansive Worlds",
      shortDescription: "Experience the thrill of the hunt in a visually breathtaking, technically groundbreaking, vast open world. Immerse yourself in the atmospheric single player campaign, or share the ultimate hunting experience with friends. ",
      gameChip: ["Hunting Animals"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/518790/theHunter_Call_of_the_Wild/",
      },
      reasonsToPlay: ["Hunting", "Nature", "Simulation"],
      awards: [""],
    },
    {
      gameId: 7,
      gameName: "Tales of Berseria",
      score: 93,
      ratings: 6782,
      gameHeader: "tales-of-berseria.jpg",
      developer: "Bandai Namco",
      shortDescription: "Players embark on a journey of self-discovery as they assume the role of Velvet, a young woman whose once kind demeanor has been replaced and overcome with a festering anger and hatred after a traumatic experience three years prior to the events within Tales of Berseria. ",
      gameChip: ["Similar to the Zelda Series"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/429660/Tales_of_Berseria/",
      },
      reasonsToPlay: ["JRPG", "Hack N' Slash", "Third Person"],
      awards: [""],
    },
  ],
  stats: {
    number: [2, 2, 2],
    category: ["Hunting Games", "Hack N' Slash Games", "Similar Indie Gems"],
    reason: ["because you like Nature", "from Developers you already like", "because you like Hollow Knight & Dead Cells"],
  },
};